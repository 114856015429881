'use client';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStatus,
} from '@floating-ui/react';
import { FC, ReactNode } from 'react';

import styles from './DialogMenu.module.scss';

interface Props {
  anchor: Element | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
}

export const DialogMenu: FC<Props> = ({ anchor, isOpen, setIsOpen, children }) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,

    elements: {
      reference: anchor,
    },
  });
  const dismiss = useDismiss(context);

  const { isMounted, status } = useTransitionStatus(context, { duration: 200 });

  const { getFloatingProps } = useInteractions([dismiss]);

  return (
    <>
      {isMounted && (
        <FloatingPortal>
          <FloatingOverlay lockScroll className={styles.root}>
            <FloatingFocusManager context={context}>
              <div className={styles.content} ref={refs.setFloating} {...getFloatingProps()} data-status={status}>
                {children}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
};
